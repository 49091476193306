import React from "react";

const RiskMatrix = ({ riskLevel }) => {
  const xAxisHeadings = ["Negligible", "Minor", "Moderate", "Significant", "Severe"];
  const yAxisHeadings = ["Very Unlikely", "Unlikely", "Possible", "Likely", "Very Likely"];

  const riskMatrix = [
    [{ id: '5A', label: 'Low Med' }, { id: '5B', label: 'Medium' }, { id: '5C', label: 'Med Hi' }, { id: '5D', label: 'High' }, { id: '5E', label: 'High' }],
    [{ id: '4A', label: 'Low' }, { id: '4B', label: 'Low Med' }, { id: '4C', label: 'Medium' }, { id: '4D', label: 'Med Hi' }, { id: '4E', label: 'High' }],
    [{ id: '3A', label: 'Low' }, { id: '3B', label: 'Low Med' }, { id: '3C', label: 'Medium' }, { id: '3D', label: 'Med Hi' }, { id: '3E', label: 'High' }],
    [{ id: '2A', label: 'Low' }, { id: '2B', label: 'Low Med' }, { id: '2C', label: 'Medium' }, { id: '2D', label: 'Medium' }, { id: '2E', label: 'Med Hi' }],
    [{ id: '1A', label: 'Low' }, { id: '1B', label: 'Low Med' }, { id: '1C', label: 'Medium' }, { id: '1D', label: 'Medium' }, { id: '1E', label: 'Medium' }],
  ];


  // Find the matching risk level label for the passed riskLevel ID
  const matchingLabel = riskMatrix.flat().find((item) => item.id === (riskLevel))?.label || "Unknown";
  console.log("Matching label:", matchingLabel);
  console.log("Risk Level:", riskLevel);

  const getBorderColor = (riskLevel) => {
    switch (riskLevel) {
      case 'Low':
        return '#28a745'; // Green
      case 'Low Med':
        return '#85bb5c'; // Greenish
      case 'Medium':
        return '#ffc107'; // Yellow/Orange
      case 'Med Hi':
        return '#ff8800'; // Orange
      case 'High':
        return '#dc3545'; // Red
      default:
        return '#ff884d'; // Fallback
    }
  };
  return (
    <div
      style={{
        padding: "20px",
        border: `2px solid ${getBorderColor(matchingLabel)}`,
        borderRadius: "5px",
        backgroundColor: "#fff",
        width: "600px",
        overflow: "auto",
      }}
    >
      <strong>Risk Level: <span style={{ fontWeight: "bold" }}>{matchingLabel}</span></strong>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "100px repeat(5, 1fr)",
          gridTemplateRows: "50px repeat(5, 1fr)",
          gap: "5px",
          marginTop: "10px",
        }}
      >
        <div></div>
        {xAxisHeadings.map((heading, index) => (
          <div key={`x-${index}`} style={{ fontSize: "0.7rem", textAlign: "center", fontWeight: "bold" }}>
            {heading}
          </div>
        ))}
        {yAxisHeadings.map((heading, rowIndex) => (
          <React.Fragment key={`row-${rowIndex}`}>
            <div style={{ fontSize: "0.7rem", textAlign: "right", paddingRight: "5px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
              {heading}
            </div>
            {riskMatrix[rowIndex].map(({ id: buttonId, label }) => (
              <div
                key={buttonId}
                style={{
                    padding: "10px",
                    width: "80px",
                    height: "40px",
                    backgroundColor: getBorderColor(label),
                    borderRadius: "3px",
                    color: buttonId === (riskLevel) ? "#000" : "#fff", // Black text for selected, white for others
                    fontWeight: "bold",
                    fontSize: "0.7rem",
                    textAlign: "center",
                    display: "flex",               // Flexbox for centering
                    alignItems: "center",          // Center vertically
                    justifyContent: "center",      // Center horizontally
                    opacity: buttonId === (riskLevel) ? "1" : "0.2", // 50% transparency for non-selected buttons
                    boxSizing: "border-box",
                  }}
                  
              >
                {label}
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default RiskMatrix;
