import React, {
  useRef,
  useCallback,
  useState,
  createContext,
  useContext,
  memo,
  useEffect,
} from "react";
import { useParams } from "react-router-dom";
import {
  ReactFlow,
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Handle,
  Position,
  useReactFlow,
  useStoreApi,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { Section } from "./styledComponents";
const apiUrl = process.env.REACT_APP_API_URL;
// Create the drag-and-drop context
const DnDContext = createContext([null, (_) => {}]);

export const DnDProvider = ({ children }) => {
  const [type, setType] = useState(null);
  return (
    <DnDContext.Provider value={[type, setType]}>
      {children}
    </DnDContext.Provider>
  );
};

export const useDnD = () => useContext(DnDContext);

// Generic styling for nodes
const nodeStyle = (isSelected, color) => ({
  padding: "10px",
  border: isSelected ? "2px solid #00ff00" : `2px solid ${color}`,
  borderRadius: "5px",
  position: "relative",
  cursor: "pointer",
});

// TextArea styling for text-wrapped input in nodes
const textAreaStyle = {
  width: "100%",
  border: "none",
  outline: "none",
  background: "transparent",
  resize: "none",
  overflowWrap: "break-word",
};

const QuestionNode = ({ data, isConnectable, id }) => {
  const selectedNodes = data.selectedNodes || [];
  const isSelected = selectedNodes.includes(id);

  return (
    <div
      onClick={() => data.onSelect(id)}
      style={nodeStyle(isSelected, "#ffcc00")}
    >
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />
      <strong>Question:</strong>
      <textarea
        placeholder="Enter Question Here..."
        value={data.label || ""}
        onChange={data.onChange}
        style={textAreaStyle}
        rows="3"
      />
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
      />
    </div>
  );
};

const AnswerNode = ({ data, isConnectable, id }) => {
  const selectedNodes = data.selectedNodes || [];
  const isSelected = selectedNodes.includes(id);

  return (
    <div
      onClick={() => data.onSelect(id)}
      style={nodeStyle(isSelected, "#00ccff")}
    >
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />
      <strong>Answer:</strong>
      <textarea
        placeholder="Enter Answer Here..."
        value={data.label || ""}
        onChange={data.onChange}
        style={textAreaStyle}
        rows="3"
      />
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
      />
    </div>
  );
};

const OutcomeNode = ({ data, isConnectable, id }) => {
  const selectedNodes = data.selectedNodes || [];
  const isSelected = selectedNodes.includes(id);

  return (
    <div
      onClick={() => data.onSelect(id)}
      style={nodeStyle(isSelected, "#ff99cc")}
    >
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />
      <strong>Outcome:</strong>
      <textarea
        placeholder="Enter Outcome Here..."
        value={data.label || ""}
        onChange={data.onChange}
        style={textAreaStyle}
        rows="3"
      />
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
      />
    </div>
  );
};

const MitigationNode = ({ data, isConnectable, id }) => {
  const selectedNodes = data.selectedNodes || [];
  const isSelected = selectedNodes.includes(id);

  return (
    <div
      onClick={() => data.onSelect(id)}
      style={nodeStyle(isSelected, "#ff99cc")}
    >
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />
      <strong>Mitigation:</strong>
      <textarea
        placeholder="Enter Mitigation Here..."
        value={data.label || ""}
        onChange={data.onChange}
        style={textAreaStyle}
        rows="3"
      />
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
      />
    </div>
  );
};

const DataEntryNode = ({ data, isConnectable, id }) => {
  const selectedNodes = data.selectedNodes || [];
  const isSelected = selectedNodes.includes(id);

  return (
    <div
      onClick={() => data.onSelect(id)}
      style={nodeStyle(isSelected, "#007bff")}
    >
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />
      <strong>Data Entry:</strong>
      <textarea
        placeholder="Enter Prompt Here..."
        value={data.label || ""}
        onChange={data.onChange}
        style={textAreaStyle}
        rows="3"
      />
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
      />
    </div>
  );
};

const RiskLevelNode = memo(({ data, isConnectable, id }) => {
  const selectedNodes = data.selectedNodes || [];
  const isSelected = selectedNodes.includes(id);

  // Function to set the border color based on risk level
  const getBorderColor = (riskLevel) => {
    switch (riskLevel) {
      case "Low":
        return "#28a745"; // Green
      case "Low Med":
        return "#85bb5c"; // Greenish
      case "Medium":
        return "#ffc107"; // Yellow/Orange
      case "Med Hi":
        return "#ff8800"; // Orange
      case "High":
        return "#dc3545"; // Red
      default:
        return "#ff884d"; // Fallback
    }
  };

  // Risk matrix structure with IDs and labels
  const riskMatrix = [
    [
      { id: "5A", label: "Low Med" },
      { id: "5B", label: "Medium" },
      { id: "5C", label: "Med Hi" },
      { id: "5D", label: "High" },
      { id: "5E", label: "High" },
    ],
    [
      { id: "4A", label: "Low" },
      { id: "4B", label: "Low Med" },
      { id: "4C", label: "Medium" },
      { id: "4D", label: "Med Hi" },
      { id: "4E", label: "High" },
    ],
    [
      { id: "3A", label: "Low" },
      { id: "3B", label: "Low Med" },
      { id: "3C", label: "Medium" },
      { id: "3D", label: "Med Hi" },
      { id: "3E", label: "High" },
    ],
    [
      { id: "2A", label: "Low" },
      { id: "2B", label: "Low Med" },
      { id: "2C", label: "Medium" },
      { id: "2D", label: "Medium" },
      { id: "2E", label: "Med Hi" },
    ],
    [
      { id: "1A", label: "Low" },
      { id: "1B", label: "Low Med" },
      { id: "1C", label: "Medium" },
      { id: "1D", label: "Medium" },
      { id: "1E", label: "Medium" },
    ],
  ];

  // X-axis (Severity) headings
  const xAxisHeadings = [
    "Negligible",
    "Minor",
    "Moderate",
    "Significant",
    "Severe",
  ];

  // Y-axis (Likelihood) headings
  const yAxisHeadings = [
    "Very Likely",
    "Likely",
    "Possible",
    "Unlikely",
    "Very Unlikely",
  ];

  return (
    <div
      style={{
        padding: "10px",
        border: isSelected
          ? "2px solid #00ff00"
          : `2px solid ${getBorderColor(data.riskLevel)}`,
        borderRadius: "5px",
        backgroundColor: "#fff",
        position: "relative",
        cursor: "pointer",
      }}
    >
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />
      <strong>
        Risk Level:{" "}
        <span style={{ fontWeight: "bold" }}>{data.riskLevel || "None"}</span>
      </strong>

      {/* Risk matrix grid */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "100px repeat(5, 1fr)", // Add extra column for Y-axis labels
          gridTemplateRows: "50px repeat(5, 1fr)", // Add extra row for X-axis labels
          gap: "5px",
          marginTop: "10px",
        }}
      >
        {/* Empty cell in top-left corner */}
        <div></div>

        {/* X-axis headings */}
        {xAxisHeadings.map((heading, index) => (
          <div
            key={`x-${index}`}
            style={{
              fontSize: "0.7rem",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {heading}
          </div>
        ))}

        {/* Y-axis headings and risk matrix */}
        {riskMatrix.map((row, rowIndex) => (
          <React.Fragment key={`row-${rowIndex}`}>
            {/* Y-axis heading */}
            <div
              style={{
                fontSize: "0.7rem",
                textAlign: "right",
                paddingRight: "5px",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
              }}
            >
              {yAxisHeadings[rowIndex]}
            </div>

            {/* Risk matrix buttons */}
            {row.map(({ id: buttonId, label }) => (
              <button
                key={buttonId}
                onClick={() => data.handleClick(id, label, buttonId)}
                style={{
                  padding: "10px",
                  width: "80px",
                  height: "40px",
                  backgroundColor: getBorderColor(label),
                  border:
                    data.selectedButton === buttonId
                      ? "1px solid black"
                      : "none",
                  borderRadius: "3px",
                  color: data.selectedButton === buttonId ? "#000" : "#fff",
                  fontWeight: "bold",
                  fontSize: "0.7rem",
                  boxSizing: "border-box",
                }}
              >
                {label}
              </button>
            ))}
          </React.Fragment>
        ))}
      </div>

      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
      />
    </div>
  );
});

const Sidebar = ({
  raId,
  setSectionId,
  sectionId,
  isButtonEnabled,
  setIsButtonEnabled,
}) => {
  const [_, setType] = useDnD();
  const [nodePosition, setNodePosition] = useState({ x: 0, y: 0 });
  const [selectedOption, setSelectedOption] = useState("Flight Safety");


  const handleAddSection = async () => {
    try {
      const payload = {
        ra_id: raId,
        title: selectedOption,
      };

      console.log("Payload being sent:", payload);
      const response = await axios.post(`${apiUrl}/ra/add-section`, payload);
      console.log("Section added successfully:", response.data);

      setSectionId(response.data.id); // Update sectionId in parent state
      setIsButtonEnabled(false);
      alert("New section added successfully!");
    } catch (error) {
      console.error(
        "Failed to add new section:",
        error.response?.data || error.message
      );
      alert("Failed to add new section. Please try again.");
    }
  };

  const onDragStart = (event, nodeType) => {
    setType(nodeType);
    event.dataTransfer.effectAllowed = "move";
    const rect = event.target.getBoundingClientRect();
    const position = { x: rect.left, y: rect.top };
    setNodePosition(position);
    console.log(`Node Type: ${nodeType}, Initial Position:`, position);
  };

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
    console.log("Selected option:", event.target.value);
  };

  return (
    <aside>
      <button
        onClick={handleAddSection}
        style={{
          width: "100%",
          padding: "10px",
          marginBottom: "15px",
          backgroundColor: "#4CAF50",
          color: "white",
          fontWeight: "bold",
          borderRadius: "5px",
          border: "none",
          cursor: "pointer",
        }}
      >
        Add New Section
      </button>

      {/* Dropdown Menu */}
      <div className="dropdown">
        <label
          htmlFor="safetyOptions"
          style={{ display: "block", marginBottom: "5px" }}
        >
          Select Category:
        </label>
        <select
          id="safetyOptions"
          value={selectedOption}
          onChange={handleDropdownChange}
          style={{
            width: "100%",
            padding: "8px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            marginBottom: "10px",
          }}
        >
          <option value="Flight Safety">Flight Safety</option>
          <option value="People">People</option>
          <option value="Property">Property</option>
        </select>
      </div>

      <div className="description">
        Drag items below to the pane on the right.
      </div>

      {/* Conditionally render draggable nodes if sectionId exists */}
      {sectionId ? (
        <>
          <div
            className="dndnode input"
            onDragStart={(event) => onDragStart(event, "question")}
            draggable
          >
            Question
          </div>
          <div
            className="dndnode"
            onDragStart={(event) => onDragStart(event, "answer")}
            draggable
          >
            Answer
          </div>
          <div
            className="dndnode data-entry"
            onDragStart={(event) => onDragStart(event, "dataEntry")}
            draggable
          >
            Data Entry
          </div>
          <div
            className="dndnode output"
            onDragStart={(event) => onDragStart(event, "outcome")}
            draggable
          >
            Outcome
          </div>
          <div
            className="dndnode mitigation"
            onDragStart={(event) => onDragStart(event, "mitigation")}
            draggable
          >
            Mitigation
          </div>
          <div
            className="dndnode risklevel"
            onDragStart={(event) => onDragStart(event, "riskLevel")}
            draggable
          >
            Risk Level
          </div>
          <button
          onClick={() =>
            window.open(`/ra/form/${raId}`, "_blank", "noopener,noreferrer")
          }
          disabled={!isButtonEnabled}
          style={{
            width: "100%",
            padding: "10px",
            marginTop: "20px",
            backgroundColor: isButtonEnabled ? "#007bff" : "#ccc",
            color: isButtonEnabled ? "white" : "#666",
            fontWeight: "bold",
            borderRadius: "5px",
            border: "none",
            cursor: isButtonEnabled ? "pointer" : "not-allowed",
          }}
        >
          Open Form
        </button>

        </>
      ) : (
        <p style={{ color: "#888", fontStyle: "italic" }}>
          Please add a section to enable draggable nodes.
        </p>
      )}
    </aside>
  );
};

// Add MitigationNode and DataEntryNode to your nodeTypes map in your main component
const nodeTypes = {
  question: QuestionNode,
  answer: AnswerNode,
  outcome: OutcomeNode,
  riskLevel: RiskLevelNode,
  mitigation: MitigationNode,
  dataEntry: DataEntryNode,
};

// Handle text changes for nodes
const handleTextChange = (id, setNodes) => (event) => {
  const newText = event.target.value;
  setNodes((nds) =>
    nds.map((node) => {
      if (node.id === id) {
        return {
          ...node,
          data: { ...node.data, label: newText },
        };
      }
      return node;
    })
  );
};

// Main Flowchart component
const DnDFlow = () => {
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const { screenToFlowPosition, getInternalNode } = useReactFlow();
  const store = useStoreApi();
  const [type] = useDnD();
  const [isShiftPressed, setIsShiftPressed] = useState(false);
  const [nodePosition, setNodePosition] = useState({ x: 0, y: 0 });
  const { raId } = useParams(); // Call useParams at the top level
  const [sectionId, setSectionId] = useState(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  // handleClick updates the risk level and selected button
  const handleClick = (nodeId, riskLevel, buttonId) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            data: { ...node.data, riskLevel, selectedButton: buttonId }, // Only update the selected button for the clicked node
          };
        }
        return node;
      })
    );
  };

  // Handle Shift key press and release
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Shift") {
        setIsShiftPressed(true);
      }
    };
    const handleKeyUp = (event) => {
      if (event.key === "Shift") {
        setIsShiftPressed(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  // Proximity-based edge connection
  const MIN_DISTANCE = 400;
  const getClosestEdge = useCallback(
    (node) => {
      const { nodeLookup } = store.getState();
      const internalNode = getInternalNode(node.id);
      const closestNode = Array.from(nodeLookup.values()).reduce(
        (res, n) => {
          if (n.id !== internalNode.id) {
            const dx =
              n.internals.positionAbsolute.x -
              internalNode.internals.positionAbsolute.x;
            const dy =
              n.internals.positionAbsolute.y -
              internalNode.internals.positionAbsolute.y;
            const d = Math.sqrt(dx * dx + dy * dy);
            if (
              d < res.distance &&
              Math.abs(dx) < MIN_DISTANCE &&
              Math.abs(dy) < MIN_DISTANCE
            ) {
              res.distance = d;
              res.node = n;
            }
          }
          return res;
        },
        { distance: Number.MAX_VALUE, node: null }
      );
      if (!closestNode.node) return null;
      const isDraggingNearOutput =
        internalNode.internals.positionAbsolute.x <
        closestNode.node.internals.positionAbsolute.x;
      return {
        id: isDraggingNearOutput
          ? `${node.id}-${closestNode.node.id}`
          : `${closestNode.node.id}-${node.id}`,
        source: isDraggingNearOutput ? node.id : closestNode.node.id,
        target: isDraggingNearOutput ? closestNode.node.id : node.id,
      };
    },
    [store, getInternalNode]
  );

  const submitData = async () => {
    try {
      const nodeData = nodes.map((node) => {
        if (node.type === "riskLevel") {
          return {
            node_id: node.id,
            section_id: sectionId,
            node_type: node.type,
            node_text: node.data.selectedButton, // Submit the button ID as node_text
            custom_data: node.data.customData || {},
            position_x: node.position.x,
            position_y: node.position.y,
          };
        } else {
          return {
            node_id: node.id,
            section_id: sectionId,
            node_type: node.type,
            node_text: node.data.label, // Submit the text for other nodes
            custom_data: node.data.customData || {},
            position_x: node.position.x,
            position_y: node.position.y,
          };
        }
      });
      const edgeData = edges.map((edge) => ({
        source_node_id: edge.source,
        target_node_id: edge.target,
      }));

      const payload = { nodes: nodeData, edges: edgeData };
      console.log("Payload:", payload);

      await axios.post(`${apiUrl}/ra/save-flow-data`, payload);
      alert("Data saved successfully!");
      setIsButtonEnabled(true);
    } catch (error) {
      console.error("Failed to save data:", error);
      alert("Failed to save data");
    }
  };

  const onNodeDrag = useCallback(
    (_, node) => {
      if (!isShiftPressed) return;
      const closeEdge = getClosestEdge(node);
      setEdges((es) => {
        const nextEdges = es.filter((e) => e.className !== "temp");
        if (
          closeEdge &&
          !nextEdges.some(
            (ne) =>
              ne.source === closeEdge.source && ne.target === closeEdge.target
          )
        ) {
          nextEdges.push({
            ...closeEdge,
            animated: true,
            style: { stroke: "#777", strokeWidth: 1, strokeDasharray: "5,5" },
            className: "temp",
          });
        }
        return nextEdges;
      });
    },
    [getClosestEdge, setEdges, isShiftPressed]
  );

  const onNodeDragStop = useCallback(
    (_, node) => {
      // Log the node's id and its final position
      console.log(`Node ID: ${node.id}, Final Position:`, node.position);

      if (!isShiftPressed) return;
      const closeEdge = getClosestEdge(node);
      setEdges((es) => {
        // Remove any temporary edges
        const nextEdges = es.filter((e) => e.className !== "temp");

        // If a valid close node is detected, add a final solid edge
        if (
          closeEdge &&
          !nextEdges.some(
            (ne) =>
              ne.source === closeEdge.source && ne.target === closeEdge.target
          )
        ) {
          nextEdges.push({
            ...closeEdge,
            animated: false, // Set animation off for the final edge
            className: "", // Remove the temporary class
            style: { stroke: "#333", strokeWidth: 2 }, // Solid line for confirmed connection
          });
        }

        return nextEdges;
      });
    },
    [getClosestEdge, isShiftPressed]
  );

  // Handle connecting nodes with edges
  const onConnect = useCallback(
    // Allow the connection if it's valid
    (params) => {
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            animated: false,
            style: { stroke: "#333", strokeWidth: 2 },
          },
          eds
        )
      );
    },
    [nodes, setEdges]
  );

  // Handle drag-over to allow dropping
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  // Handle dropping a new node
  const getId = () => uuidv4();

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      if (!type) return;

      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const id = getId();

      const newNode = {
        id,
        type,
        position,
        data: {
          label: "",
          onChange:
            type === "riskLevel"
              ? (riskLevel) => handleClick(id, riskLevel)
              : handleTextChange(id, setNodes),
          onSelect: (nodeId) => {
            setNodes((nds) =>
              nds.map((node) =>
                node.id === nodeId
                  ? { ...node, data: { ...node.data, selected: true } }
                  : node
              )
            );
          },
        },
      };

      setNodes((nds) => [...nds, newNode]);
    },
    [screenToFlowPosition, type, setNodes]
  );

  return (
    <div className="dndflow" style={{ display: "flex", height: "100vh" }}>
     <Sidebar
  raId={raId}
  setSectionId={setSectionId}
  sectionId={sectionId}
  isButtonEnabled={isButtonEnabled}
  setIsButtonEnabled={setIsButtonEnabled}
/>

      <div
        className="reactflow-wrapper"
        ref={reactFlowWrapper}
        style={{ width: "100%", height: "100%" }}
      >
        <ReactFlow
          nodes={nodes.map((node) => ({
            ...node,
            data: {
              ...node.data,
              handleClick, // Pass handleClick to every node's data object
              setNodes, // Pass setNodes to every node's data object
            },
          }))}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onDrop={onDrop}
          onDragOver={onDragOver}
          onNodeDrag={onNodeDrag}
          onNodeDragStop={onNodeDragStop}
          nodeTypes={{
            question: QuestionNode,
            answer: AnswerNode,
            outcome: OutcomeNode,
            riskLevel: RiskLevelNode, // Ensure RiskLevelNode gets handleClick and setNodes
            mitigation: MitigationNode,
            dataEntry: DataEntryNode,
          }}
          fitView
        >
          <Controls />
        </ReactFlow>
        <button
          onClick={submitData}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            padding: "10px 20px",
            zIndex: 10,
          }}
        >
          Save Flow Data
        </button>
      </div>
    </div>
  );
};

// Main export that wraps everything inside the DnDProvider and ReactFlowProvider
export default () => (
  <ReactFlowProvider>
    <DnDProvider>
      <DnDFlow />
    </DnDProvider>
  </ReactFlowProvider>
);
