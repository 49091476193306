import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LocationForm from "./create_location.js"; // Import LocationForm
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
const apiUrl = process.env.REACT_APP_API_URL;

const RiskAssessmentForm = ({ onAssessmentAdded }) => {
  const navigate = useNavigate();

  const [riskAssessmentHeader, setRiskAssessmentHeader] = useState({
    title: "",
    ra_owner: "",
    user_id: "",
    location_id: "", // Set location_id for selected location
    is_active: false,
  });

  const [locations, setLocations] = useState([]); // Store list of locations
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [showLocationForm, setShowLocationForm] = useState(false); // Toggle for location form

  
  const fetchLocations = async () => {
    try {
      console.log("Fetching all active locations...");
      
      const response = await axios.get(`${apiUrl}/audit/locations`);
      setLocations(response.data);
      console.log("Locations fetched: ", response.data);
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };
  
  // Fetch the org_id (ra_owner) from membership token
  useEffect(() => {
    const membershipToken = localStorage.getItem("MToken");
  
    axios
      .post(`${apiUrl}/decode-membership-token/${membershipToken}`)
      .then((res) => {
        console.log("Decoded membership token: ", res.data);
        setRiskAssessmentHeader((prevState) => ({
          ...prevState,
          ra_owner: res.data.data.membership_data.org_id,
        }));
      })
      .catch((error) => {
        console.error("Error decoding membership token:", error);
      });
  }, []);
  
  // Call fetchLocations after ra_owner is set
  useEffect(() => {
    if (riskAssessmentHeader.ra_owner) {
      fetchLocations(riskAssessmentHeader.ra_owner);
    }
  }, [riskAssessmentHeader.ra_owner]);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setRiskAssessmentHeader((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  

  const handleLocationSelect = (e) => {
    const locationId = e.target.value;
    console.log("Location selected: ", locationId);
    setRiskAssessmentHeader((prevState) => ({
      ...prevState,
      location_id: locationId,
    }));
  };

  const handleLocationAdded = (newLocation) => {
    console.log("Location added: ", newLocation);
  
    // Refetch the location list after adding a new location
    const fetchLocations = async () => {
      try {
        const response = await axios.get(`${apiUrl}/audit/locations`);
        setLocations(response.data);  // Update the locations state
        console.log("Updated locations fetched after adding: ", response.data);
      } catch (error) {
        console.error("Error fetching updated locations:", error);
      }
    };
  
    fetchLocations();
  
    setRiskAssessmentHeader((prevState) => ({
      ...prevState,
      location_id: newLocation.id,  // Automatically select the new location
    }));
  
    setShowLocationForm(false); // Hide the form after adding the location
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // First, check if a risk assessment with the same title already exists
      const response = await axios.get(`${apiUrl}/ra/check_title/${riskAssessmentHeader.title}`);
      if (response.data.exists) {
        console.error("Risk Assessment with this title already exists.");
        alert("A Risk Assessment with this title already exists. Please choose a different title.");
        return; // Stop the form submission
      }
  
      // Proceed with submission if the title is unique
      const newRiskAssessmentHeader = {
        title: riskAssessmentHeader.title,
        ra_owner: riskAssessmentHeader.ra_owner,
        location_id: riskAssessmentHeader.location_id,
        is_active: true,
      };
  
      const submitResponse = await axios.post(`${apiUrl}/ra/generate_ra_header`, newRiskAssessmentHeader);
      console.log("Risk Assessment Created: ", submitResponse.data);
  
      // Navigate to the FlowChart page with the newly created raId
      const { id: raId } = submitResponse.data;
      navigate(`/ra/${raId}`);
  
    } catch (error) {
      console.error("Error creating risk assessment:", error);
    }
  };
  

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      {isFormVisible && (
        <form onSubmit={handleSubmit} className="flex flex-col w-full max-w-md p-8 space-y-8 bg-white shadow-xl rounded-lg">
          <div className="flex items-center">
            <div className="flex-grow">
              <label className="text-lg mb-2">Risk Assessment Title:</label>
              <input
                type="text"
                name="title"
                value={riskAssessmentHeader.title}
                onChange={handleChange}
                className="w-full border h-10 border-gray-300 px-3 rounded"
              />
            </div>
          </div>

          <div className="flex items-center">
  <select
    name="location_id"
    value={riskAssessmentHeader.location_id}
    onChange={handleLocationSelect}
    className="w-full border h-10 border-gray-300 px-3 rounded"
  >
    <option value="">Select a Location</option>
    {locations.map((location) => (
      <option key={location.id} value={location.id}>
        {location.location_name}
      </option>
    ))}
  </select>

  {/* Add New Location "+" Button */}
  <button
    type="button"
    onClick={() => {
      console.log("Add New Location button clicked.");
      setShowLocationForm(true);
    }}
    className="ml-2 bg-primary hover:bg-secondary text-white font-bold py-2 px-3 rounded"
  >
    <FontAwesomeIcon icon={faPlus} />
  </button>
</div>


          <button
            type="submit"
            className="bg-primary text-white font-bold py-2 px-4 rounded block mx-auto"
          >
            Create Risk Assessment
          </button>
        </form>
      )}

      {/* Location Form Modal */}
      {showLocationForm && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <LocationForm onLocationAdded={handleLocationAdded} />
          </div>
        </div>
      )}
    </div>
  );
};

export default RiskAssessmentForm;
