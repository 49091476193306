import React, { useState } from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const LocationForm = ({ onLocationAdded }) => {
  const [locationHeader, setLocationHeader] = useState({
    location_name: "",
    address: "",
    what3words: "",
    longitude: "",
    latitude: "",
    is_active: false,
  });

  const [isFormVisible, setIsFormVisible] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocationHeader((prevState) => ({
      ...prevState,
      [name]: value.toUpperCase(),
    }));
  };

  const handleFind = async () => {
    try {
      const geocodeResponse = await axios.post(`${apiUrl}/audit/get_address`, {
        location_name: locationHeader.location_name,
      });

      setLocationHeader((prevState) => ({
        ...prevState,
        address: geocodeResponse.data.address,
        what3words: geocodeResponse.data.what3words,
        longitude: geocodeResponse.data.longitude,
        latitude: geocodeResponse.data.latitude,
      }));
    } catch (error) {
      console.error("Error fetching address and what3words:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newLocationHeader = {
        location_name: locationHeader.location_name,
        address: locationHeader.address,
        what3words: locationHeader.what3words,
        longitude: locationHeader.longitude,
        latitude: locationHeader.latitude,
        is_active: true,
      };

      const response = await axios.post(`${apiUrl}/audit/generate_location_header`, newLocationHeader);
      const newLocation = response.data;

      setIsFormVisible(false);

      // Notify the parent component to refresh the dropdown with the newly added location
      if (onLocationAdded) {
        onLocationAdded(newLocation); // Pass the new location object to the parent component
      }
    } catch (error) {
      console.error("Error creating new location:", error);
    }
  };

  return (
    <div>
      {isFormVisible && (
        <form
          onSubmit={handleSubmit}
          className="flex flex-col w-full max-w-md p-8 space-y-8 bg-white shadow-xl rounded-lg"
        >
          <div className="flex items-center">
            <div className="flex-grow">
              <label className="text-lg mb-2">Location Name:</label>
              <input
                type="text"
                name="location_name"
                value={locationHeader.location_name}
                onChange={handleChange}
                className="w-full border h-10 border-gray-300 px-3 rounded"
              />
            </div>
            <button
              type="button"
              onClick={handleFind}
              className="ml-4 bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded"
            >
              Find
            </button>
          </div>
          <div>
            <label className="text-lg mb-2">Address:</label>
            <input
              type="text"
              name="address"
              value={locationHeader.address}
              onChange={handleChange}
              className="w-full border h-10 border-gray-300 px-3 rounded"
              disabled
            />
          </div>
          <div>
            <label className="text-lg mb-2">What3Words:</label>
            <input
              type="text"
              name="what3words"
              value={locationHeader.what3words}
              onChange={handleChange}
              className="w-full border h-10 border-gray-300 px-3 rounded"
              disabled
            />
          </div>

          <button
            type="submit"
            className="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded block mx-auto"
          >
            Add Location
          </button>
        </form>
      )}
    </div>
  );
};

export default LocationForm;
