import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Login from "./components/Login.js";
import Admin from "./pages/AdminPage.js";
import Register from "./components/registerForm.js";
import FlowChart from "./components/flowBuilder.jsx";
import CreateOrganisation from './pages/CreateOrganisation.js';
import Inactive from "./pages/inactive";
import Home from "./pages/Home.js";
import List_Organisations from "./pages/listOrganisations.js";
import EditOrganisation from "./pages/edit_org";
import AddOrganisationFunction from "./pages/AddOrganisationFunction.js";
import CreateMembership from "./pages/add_membership";
import Profiles from './pages/all_global_profiles.js';
import { ThemeProvider } from './components/theme.js';
import AuditHomePage from "./pages/home_audit.js";
import MaintHomePage from "./pages/home_maint.js";
import ChecklistHeaderForm from "./pages/createChecklistHeader";
import JobcardHeaderForm from "./pages/createJobcardHeader.js";
import AuditChecklistComponent from "./pages/create_audit_checklist.js";
import JobcardContentComponent from "./pages/create_jobcard_content.js";
import MaintHeaderForm from "./pages/createWorkpackHeader.js";
import CreateAuditPage from "./pages/create_audit.js";
import CreateMaintPage from "./pages/create_workpack.js";
import AuditContentLive from "./pages/auditLive.js";
import MaintContentLive from "./pages/maintLive.js";
import NewFlightPage from "./pages/flightLogForm.js";
import EditFlightPage from "./pages/flightLogFormEdit.js";
import AuditProgramForm from "./pages/create_program.js";
import MaintProgramForm from "./pages/create_maint_program.js";
import Dashboard from "./pages/auditScoresDashboard.js";
import Assets from "./pages/assets.js"; 
import AuditProgram from "./pages/home_audit_program.js";
import MaintProgram from "./pages/home_maint_program.js";
import MonthDetailPage from "./pages/auditMonth.js"; 
import RiskAssessmentCreate from "./pages/create_ra.js";
import FlightSelect from "./pages/flight-select.js";
import RiskAssessmentForm from "./pages/ra_form.js";
import RiskAssessmentResults from "./pages/ra_results.js";

// Function to dynamically set the document title based on the route
function usePageTitle() {
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/login':
        document.title = 'Login';
        break;
      case '/home':
        document.title = 'Home';
        break;
      case '/admin':
        document.title = 'Admin Dashboard';
        break;
      case '/admin/list_all_organisations':
        document.title = 'Organisations List';
        break;
      case '/flight/home':
        document.title = 'Management Dashboard';
        break;
      case '/maint/home':
        document.title = 'Maintenance Dashboard';
        break;
      case '/flight/createChecklistHeader':
        document.title = 'New Testcard';
        break;
      case '/flight/create_test_blueprint':
        document.title = 'Build Testcard';
        break;
      case '/maint/create_jobcard_blueprint':
        document.title = 'Build Jobcard';
        break;


      case '/flight/create_test': 
        document.title = 'Flight Home';
        break;
      case '/flight/audit_live':
        document.title = 'Test Results';
        break;
      case '/maint/maint_live':
        document.title = 'Jobcard Results';
        break;
      case '/flight/new_flight':
        document.title = 'New Flight';
        break;
      case '/flight/edit_flight':
        document.title = 'Edit Flight';
        break;
      case '/flight/new_schedule':
        document.title = 'New Schedule';
        break;
        case '/maint/new_program':  
        document.title = 'New Program';
        break;

      case '/flight/test_schedule':
        document.title = 'Test Schedule';
        break;
      case '/maint/program_scedule':
        document.title = 'Program Schedule';
        break;

      case '/flight/month-detail':
        document.title = 'Month Detail';
        break;
      case '/admin/flight_dashboard':
        document.title = 'Flight Dashboard';
        break;
      case '/admin/assets':
        document.title = 'Assets';
        break;

      // Add more cases for different routes as needed
      default:
        document.title = 'NPCC Drones';
    }
  }, [location.pathname]);
}

function App() {
  return (
    <ThemeProvider> 
      <Router>
        <PageTitleManager />  
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/inactive" element={<Inactive />} />
          
        
          
          
          // Admin Routes
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/add_org_function" element={<AddOrganisationFunction />} />
          <Route path="/admin/edit_org" element={<EditOrganisation />} />
          <Route path="/admin/create_org" element={<CreateOrganisation />} />
          <Route path="/admin/list_all_organisations" element={<List_Organisations />} />
          <Route path="/admin/all_global_profiles" element={<Profiles />} />
          <Route path="/admin/add_membership" element={<CreateMembership />} />
          <Route path="/admin/flight_dashboard" element={<Dashboard />} />
          <Route path="/admin/assets" element={<Assets />} /> 
          
          // Flight Routes
          <Route path="/flight/home" element={<AuditHomePage />} />
          <Route path="/flight/createChecklistHeader" element={<ChecklistHeaderForm />} />
          <Route path="/flight/create_test_blueprint/:checklistId" element={<AuditChecklistComponent />} />
          <Route path="/flight/create_test/:auditId" element={<CreateAuditPage />} />
          <Route path="/flight/audit_live/:auditId" element={<AuditContentLive />} />
          <Route path="/flight/flight_select" element={<FlightSelect />} />
          <Route path="/flight/new_flight" element={<NewFlightPage />} />
          <Route path="/flight/edit_flight/:flightId" element={<EditFlightPage />} />
          <Route path="/flight/new_schedule" element={<AuditProgramForm />} />
          <Route path="/flight/test_schedule/:programId" element={<AuditProgram />} />
          <Route path="/flight/month-detail/:year/:month/:programId" element={<MonthDetailPage />} /> 

          // Maintenance Routes
          <Route path="/maint/home" element={<MaintHomePage />} />
          <Route path="/maint/createJobcardHeader" element={<JobcardHeaderForm />} />
          <Route path="/maint/create_jobcard_blueprint/:checklistId" element={<JobcardContentComponent />} />
          <Route path="/maint/createWorkpackHeader" element={<MaintHeaderForm />} />
          <Route path="/maint/create_workpack/:auditId" element={<CreateMaintPage />} />
          <Route path="/maint/maint_live/:auditId" element={<MaintContentLive />} />
          <Route path="/maint/new_program" element={<MaintProgramForm />} />
          <Route path="/maint/program_schedule/:programId" element={<MaintProgram />} />
          
          
            {/*Test area*/}
          <Route path="/ra/create" element={<RiskAssessmentCreate />} />
          <Route path="/ra/:raId" element={<FlowChart />} />
          <Route path="/ra/form/:raId" element={<RiskAssessmentForm />} />
          <Route path="/ra/results/:raId" element={<RiskAssessmentResults />} />

        </Routes>
      </Router>
    </ThemeProvider>
  );
}

// A separate component that manages the page title
function PageTitleManager() {
  usePageTitle();
  return null;  // This component is only for managing the title, so it doesn't render anything.
}

export default App;
