import React from "react";
import { useLocation } from "react-router-dom";
import RiskMatrix from "../components/RiskMatrix.js"; // Import the RiskMatrix component

const RiskAssessmentResults = () => {
  const location = useLocation();
  const riskLevel = location.state?.riskLevel || "None"; // Retrieve the risk level ID or default
console.log("Risk Level: ", riskLevel);
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-10 rounded-lg shadow-lg w-full" style={{ maxWidth: "680px" }}>
        <h2 className="text-2xl font-bold mb-6 text-center">Risk Assessment Results</h2>
        <RiskMatrix riskLevel={riskLevel} />
      </div>
    </div>
  );
};

export default RiskAssessmentResults;
