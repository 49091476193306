import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

const RiskAssessmentForm = () => {
  const { raId } = useParams();
  const [nodes, setNodes] = useState([]);
  const [connections, setConnections] = useState([]);
  const [currentNode, setCurrentNode] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/ra/get-flow-data/${raId}`);
        setNodes(response.data.nodes);
        setConnections(response.data.edges);
      } catch (error) {
        console.error("Error fetching flow data:", error);
      }
    };

    fetchData();
  }, [raId]);

  useEffect(() => {
    if (nodes.length > 0 && connections.length > 0) {
      initializeForm(nodes);
    }
  }, [nodes, connections]);

  const initializeForm = (nodes) => {
    const startNode = nodes.find(
      (node) =>
        node.node_type === "question" &&
        !connections.some((conn) => conn.target_node_id === node.node_id)
    );
    setCurrentNode(startNode);
  };

  const getNextNode = (selectedNodeId) => {
    const connection = connections.find(
      (conn) => conn.source_node_id === selectedNodeId
    );
    return connection ? nodes.find((node) => node.node_id === connection.target_node_id) : null;
  };

  const handleAnswerSelect = (answerId) => {
    const nextNode = getNextNode(answerId);
    console.log("Next node:", nextNode);
    if (nextNode) {
      if (nextNode.node_type === "riskLevel") {
        console.log("Navigating to :", nextNode.node_type); // Check this log for the correct value
        console.log("Navigating to results with riskLevel:", nextNode.node_text); // Check this log for the correct value
        navigate(`/ra/results/${raId}`, { state: { riskLevel: nextNode.node_text } });
      } else {
        setCurrentNode(nextNode);
      }
    } else {
      alert("No further nodes. Risk assessment complete.");
      navigate(`/ra/results/${raId}`);
    }
  };
  
  

  // Render different node types
  const renderNode = () => {
    if (!currentNode) return null;

    switch (currentNode.node_type) {
      case "question":
        return (
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-xl font-bold mb-4">{currentNode.node_text}</h2>
            <div className="space-y-2">
              {nodes
                .filter(
                  (node) =>
                    node.node_type === "answer" &&
                    connections.some(
                      (conn) =>
                        conn.source_node_id === currentNode.node_id &&
                        conn.target_node_id === node.node_id
                    )
                )
                .map((answer) => (
                  <button
                    key={answer.node_id}
                    onClick={() => handleAnswerSelect(answer.node_id)}
                    className="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                  >
                    {answer.node_text || "Select an Option"}
                  </button>
                ))}
            </div>
          </div>
        );
      case "outcome":
        return (
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-xl font-bold mb-4">Outcome: {currentNode.node_text}</h2>
            <button
              onClick={() => handleAnswerSelect(currentNode.node_id)}
              className="mt-4 bg-green-500 text-white font-bold py-2 px-4 rounded hover:bg-green-700"
            >
              Continue
            </button>
          </div>
        );
      case "mitigation":
        return (
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-xl font-bold mb-4">Mitigation: {currentNode.node_text}</h2>
            <button
              onClick={() => handleAnswerSelect(currentNode.node_id)}
              className="mt-4 bg-green-500 text-white font-bold py-2 px-4 rounded hover:bg-green-700"
            >
              Continue
            </button>
          </div>
        );
      case "dataEntry":
        return (
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-xl font-bold mb-4">Data Entry: {currentNode.node_text}</h2>
            <input
              type="text"
              placeholder="Enter data"
              className="w-full border border-gray-300 px-3 py-2 rounded"
            />
            <button
              onClick={() => handleAnswerSelect(currentNode.node_id)}
              className="mt-4 bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
            >
              Continue
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      {renderNode()}
    </div>
  );
};

export default RiskAssessmentForm;
