import React, { useState, useEffect, useContext } from "react";
import auditProtection from "../hoc/auditProtection.js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css"; // Import the CSS for styling

// Import components
import CircularScore from "../components/CircularScore.js";
import CircularProgress from "../components/CircularProgress.js";

import "../components/styles.css";
import { ThemeProvider } from "styled-components";
import { ThemeContext } from "../components/theme.js";
import { STab } from "../components/styledComponents.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faExclamationTriangle,
  faHomeLgAlt,
  faTimes,
  faPlaneSlash,
  faScrewdriverWrench,
  faPlaneCircleCheck,
  
} from "@fortawesome/free-solid-svg-icons";

const apiUrl = process.env.REACT_APP_API_URL;

function formatDate(dateString) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = new Date(dateString).toLocaleDateString(
    undefined,
    options
  );
  return formattedDate;
}

function MaintHomePage(props) {
  const { theme } = useContext(ThemeContext);
  const [userID, setUserID] = useState("");
  const [checklists, setChecklists] = useState([]);
  const [maintPrograms, setMaintPrograms] = useState([]);
  const [maint, setmaint] = useState([]);
  const [activeTab, setActiveTab] = useState("maintPrograms");
  const [progress, setProgress] = useState({ total: 0, answered: 0 });
  const mToken = localStorage.getItem("MToken");
  const navigate = useNavigate();
  const [flightMinutesMap, setFlightMinutesMap] = useState({}); // To store flight minutes for each drone

  const fetchUserID = async () => {
    const token = localStorage.getItem("access_token");
    try {
      const userRes = await axios.get(apiUrl + "/get_user_id_from_token", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const userID = userRes.data.user_id;
      setUserID(userID);
    } catch (error) {
      console.error("There was an error!", error.response);
      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    }
  };

  // Fetch flight minutes for a drone
  const fetchFlightMinutes = async (droneId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/drone/${droneId}/flight_minutes`
      );
      return response.data.flight_minutes;
    } catch (error) {
      console.error("Error fetching flight minutes:", error);
      return null;
    }
  };

  // Fetch flight minutes for all programs
  useEffect(() => {
    async function loadFlightMinutes() {
      const minutesMap = {};
      await Promise.all(
        maintPrograms.map(async (program) => {
          const minutes = await fetchFlightMinutes(program.asset_id);
          minutesMap[program.asset_id] = minutes; // Store flight minutes for each drone
        })
      );
      setFlightMinutesMap(minutesMap); // Update state with flight minutes
    }

    loadFlightMinutes();
  }, [maintPrograms]); // Re-run when maintPrograms changes

  const fetchMaintByOrgFromToken = async () => {
    try {
      const mToken = localStorage.getItem("MToken");
      if (!mToken) {
        navigate("/maint/home"); // redirect to home if there is no MToken
        return;
      }

      // Decode the token to get membership data
      const response = await axios.post(
        `${apiUrl}/decode-membership-token/${mToken}`
      );
      const membershipData = response.data;

      if (
        membershipData &&
        membershipData.data &&
        membershipData.data.membership_data
      ) {
        const orgId = membershipData.data.membership_data.org_id; // Correctly access org_id
        //console.log("Organization ID:", orgId);

        // Fetch Maint using the org_id
        const maintResponse = await axios.get(
          `${apiUrl}/maint/all-maint/${orgId}`
        );
        const maintData = maintResponse.data;
        console.log("maint by Org:", maintData);

        // Update the maint state with the fetched data
        setmaint(maintData);
      } else {
        console.error("Membership data not found in token");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchMaintProgramsByOrg = async () => {
    try {
      const mToken = localStorage.getItem("MToken");
      if (!mToken) {
        navigate("/home"); // redirect to home if there is no MToken
        return;
      }

      // Decode the token to get membership data
      const response = await axios.post(
        `${apiUrl}/decode-membership-token/${mToken}`
      );
      const membershipData = response.data;

      if (membershipData && membershipData.data) {
        const orgId = membershipData.data.membership_data.org_id; // Correctly access org_id
        console.log("Organization ID:", orgId);

        // Fetch Maint Programs using the org_id
        const maintProgramsResponse = await axios.get(
          `${apiUrl}/maint/programs/${orgId}`
        );
        const maintProgramsData = maintProgramsResponse.data;
        console.log("Maint Programs:", maintProgramsData);

        // Update the maintPrograms state with the fetched data
        setMaintPrograms(maintProgramsData);
      } else {
        console.error("Membership data not found in token");
      }
    } catch (error) {
      console.error("Error fetching maint programs:", error);
    }
  };

  const fetchBlueprints = async (userID) => {
    if (!userID) {
      
      return; // Exit the function if userID is not present
    }
  
    try {
      const response = await axios.post(
        apiUrl + "/maint/user_blueprint_headers/",
        {
          user_id: userID,
        }
      );
      const userChecklists = response.data;
      console.log("User Checklists:", userChecklists);

      setChecklists(userChecklists);
    } catch (error) {
      console.error("Error fetching checklists:", error);
    }
  };

  const renderMaintPrograms = () => {
    const currentDate = new Date(); // Current date for comparison

    return maintPrograms.map((program) => {
      // Ensure that tasks exist before trying to find the next maintenance task
      if (!program.tasks || program.tasks.length === 0) {
        return (
          <div
            key={program.id}
            className="border p-4 rounded-lg shadow-md"
            onClick={() => navigate(`/maint/program_schedule/${program.id}`)}
          >
            <div>
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <h3 className="text-xl font-semibold">
                    {program.program_name}
                  </h3>
                  <button
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent parent click event from firing
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPen}
                      style={{
                        fontSize: "0.6em",
                        color: "grey",
                        marginLeft: "8px",
                      }} // Added marginLeft to move the icon to the right
                    />
                  </button>
                </div>
                <button
                  className="ml-2 text-red-400"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent parent click event from firing
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <p>Start Date: {formatDate(program.start_date)}</p>
              <p>
  Asset: {program.asset_code_name}
</p>
{/* Only display flight minutes if they are greater than 0 */}
{flightMinutesMap[program.asset_id] > 0 && (
  <p>Current Flight Minutes: {flightMinutesMap[program.asset_id]}</p>
)}


              {/* No tasks available */}
              <p className="text-blue-500">No tasks setup for this program</p>
            </div>
          </div>
        );
      }

      // Find the task with the earliest `drop_dead_days` and lowest `drop_dead_flight_minutes`
      const nextMaintenanceTask = program.tasks.reduce(
        (earliestTask, currentTask) => {
          const currentTaskDate = new Date(currentTask.drop_dead_days);
          const earliestTaskDate = new Date(earliestTask.drop_dead_days);

          if (
            currentTaskDate < earliestTaskDate ||
            (currentTaskDate.getTime() === earliestTaskDate.getTime() &&
              currentTask.drop_dead_flight_minutes <
                earliestTask.drop_dead_flight_minutes)
          ) {
            return currentTask;
          }

          return earliestTask;
        },
        program.tasks[0]
      );

      const dropDeadDate = nextMaintenanceTask.drop_dead_days
      ? new Date(nextMaintenanceTask.drop_dead_days)
      : null;
    
    const showPlaneSlashIcon =
      (flightMinutesMap[program.asset_id] !== null &&
        flightMinutesMap[program.asset_id] >= nextMaintenanceTask.drop_dead_flight_minutes) 
        
    const showPlaneSlashIconDate =
      (dropDeadDate !== null && dropDeadDate <= currentDate); // Ensure date is in the past or today
    
 
    

      return (
        <div
          key={program.id}
          className="border p-4 rounded-lg shadow-md"
          onClick={() => navigate(`/maint/program_schedule/${program.id}`)}
        >
          <div>
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <h3 className="text-xl font-semibold">
                  {program.program_name}
                </h3>
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent parent click event from firing
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPen}
                    style={{
                      fontSize: "0.6em",
                      color: "grey",
                      marginLeft: "8px",
                    }} // Added marginLeft to move the icon to the right
                  />
                </button>
              </div>
              <button
                className="ml-2 text-red-400"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent parent click event from firing
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>

            <p>Start Date: {formatDate(program.start_date)}</p>
            <p>
  Asset: {program.asset_code_name}
</p>
{/* Only display flight minutes if they are greater than 0 */}
{flightMinutesMap[program.asset_id] > 0 && (
  <p>Current Flight Minutes: {flightMinutesMap[program.asset_id]}</p>
)}




            {/* Conditional rendering for next maintenance */}

            {/* Maintenance Due */}
            <p style={{ marginTop: "15px" }}>
              <strong>Next Maintenance Due:</strong>
            </p>
            {/* Date and icon on a new line */}
            {nextMaintenanceTask.drop_dead_days && (
              <p>
                {/* Show plane icon if conditions are met, otherwise show green check icon */}
                {showPlaneSlashIconDate ? (
                  <FontAwesomeIcon
                    icon={faPlaneSlash}
                    style={{ color: "red", marginRight: "8px" }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faPlaneCircleCheck}
                    style={{ color: "limegreen", marginRight: "8px" }}
                  />
                )}
                <span>{formatDate(nextMaintenanceTask.drop_dead_days)}</span>
              </p>
            )}

            {/* Flight Minutes */}
            {nextMaintenanceTask.drop_dead_flight_minutes && (
              <p>
                {/* Show plane icon if conditions are met, otherwise show green check icon */}
                {showPlaneSlashIcon ? (
                  <FontAwesomeIcon
                    icon={faPlaneSlash}
                    style={{ color: "red", marginRight: "8px" }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faPlaneCircleCheck}
                    style={{ color: "limegreen", marginRight: "8px" }}
                  />
                )}
                {`${nextMaintenanceTask.drop_dead_flight_minutes} Flight minutes`}
              </p>
            )}

<button
  className="bg-blue-500 text-white p-2 mt-4 rounded-lg"
  onClick={(e) => {
    e.stopPropagation(); // Stop the event from bubbling up
    navigate(`/maint/createWorkpackHeader`, {
      state: {
        programId: program.id,
        programName: program.program_name,
        tasks: program.tasks, // Pass the list of tasks for multi-select
      },
    });
  }}
>
  Start Maintenance
</button>

            {/* List all tasks */}
            <div className="mt-4">
              <p className="font-semibold">Tasks:</p>
              <div>
                {program.tasks.map((task) => (
                  <div key={task.task_id} className="flex items-center mb-2">
                    <FontAwesomeIcon
                      icon={faScrewdriverWrench}
                      style={{ marginRight: "8px", cursor: "pointer" }} // Add pointer cursor for clickability
                      onClick={() => launchWorkPack(task.task_id)} // Attach the click event
                    />
                    {task.task_name}
                  </div>
                ))}
              </div>
            </div>


          </div>
        </div>
      );
    });
  };

  const renderMaint = () => {
    return maint.map((maint) => (
      <div
        key={maint.id}
        className="border p-4 rounded-lg shadow-md"
        onClick={() => navigate(`/maint/create_workpack/${maint.id}`)}
      >
        <div>
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <h3 className="text-xl font-semibold mr-1">{maint.maint_name}</h3>
              ...
              <button
                onClick={(e) => {
                  e.stopPropagation(); // Prevent parent click event from firing
                  handleEditMaint(
                    maint.id,
                    maint.maint_name,
                    maint.date_scheduled,
                    maint.maintainer
                  );
                }}
              >
                <FontAwesomeIcon
                  icon={faPen}
                  style={{ fontSize: "0.6em", color: "grey" }}
                />
              </button>
            </div>
            <button
              className="ml-2 text-red-400"
              onClick={(e) => {
                e.stopPropagation(); // Prevent parent click event from firing
                handleDeleteMaint(maint.id);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>

          <p>Date Scheduled: {formatDate(maint.date_scheduled)}</p>
          {/* Assuming you might add date_completed in the future */}
          <p>
            Date Completed:{" "}
            {maint.date_completed
              ? formatDate(maint.date_completed)
              : "Pending"}
          </p>
          <p>Status: {maint.status}</p>
        </div>
        <div className="flex justify-end">
          
          <div className="ml-1">
            <ProgressTracker maintId={maint.id} />
          </div>
        </div>
      </div>
    ));
  };

  const renderBlueprints = () => {
    return checklists.map((checklist) => (
      <div
        key={checklist.id}
        onClick={() =>
          navigate(`/maint/create_jobcard_blueprint/${checklist.id}`)
        }
        className="border p-4 rounded-lg shadow-md"
      >
        <div>
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <h3 className="text-xl font-semibold mr-1">
                {checklist.checklist_name}
              </h3>

              <button
                onClick={(e) => {
                  e.stopPropagation(); // Prevent parent click event from firing
                  handleEditChecklist(checklist.id, checklist.checklist_name);
                }}
              >
                ..
                <FontAwesomeIcon
                  icon={faPen}
                  style={{ fontSize: "0.6em", color: "grey" }}
                />
              </button>
            </div>
            <button
              className="ml-2 text-red-400"
              onClick={(e) => {
                e.stopPropagation(); // Prevent parent click event from firing
                handleDeleteChecklist(checklist.id);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <p>Date Created: {formatDate(checklist.created_at)}</p>
          <p>Organisation: {checklist.name}</p>
        </div>

        {/* Icons for actions */}
      </div>
    ));
  };

  const MaintScores = ({ maintId }) => {
    const [score, setScore] = useState(null);
    const [unsafe, setUnsafe] = useState(false);

    useEffect(() => {
      const fetchScore = async () => {
        try {
          const response = await axios.get(
            `${apiUrl}/maint/scores/maint/${maintId}`
          );
          // Assuming the response is an array and you need the first item's average_score
          const fetchedScore = response.data[0]?.average_score;
          if (fetchedScore !== undefined) {
            setScore(fetchedScore);
          } else {
            // Handle the case where average_score is not in the response
            console.error("Average score not found in the response");
            setScore(0);
          }
        } catch (error) {
          console.error("Error fetching score", error);
          setScore(0); // Or handle differently
        }
      };

      const checkUnsafe = async () => {
        try {
          const response = await axios.get(
            `${apiUrl}/maint/check_unsafe/${maintId}`
          );
          setUnsafe(response.data);
        } catch (error) {
          console.error("Error checking for unsafe entries", error);
        }
      };

      if (maintId) {
        fetchScore();
        checkUnsafe();
      }
    }, [maintId, apiUrl]);

    if (score === null || score === 0) {
      return null; // Don't render if score is zero or not fetched
    }

    return (
      <div
        className="circular-score-container"
        style={{ display: "flex", alignItems: "center" }}
      >
        {unsafe && (
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            color="red"
            className="unsafe-icon"
            style={{ marginRight: "8px", fontSize: "34px" }}
          />
        )}
        <CircularScore averageScore={score} />
      </div>
    );
  };

  const ProgressTracker = ({ maintId }) => {
    const [progress, setProgress] = useState({ total: 0, answered: 0 });

    useEffect(() => {
      const fetchProgress = async () => {
        try {
          const response = await axios.get(
            apiUrl + `/maint/progress/${maintId}`
          ); // Assuming this is your endpoint to fetch progress
          setProgress(response.data);
        } catch (error) {
          console.error("Error fetching progress:", error);
        }
      };

      fetchProgress();
    }, [maintId]);

    return (
      <CircularProgress total={progress.total} answered={progress.answered} />
    );
  };

  const launchWorkPack = (taskId) => {
    // Redirect to the workpack page with the task ID
  };

  const hideChecklist = async (checklistId) => {
    try {
      await axios.put(`${apiUrl}/maint/hide_checklist/${checklistId}`);
      fetchBlueprints(userID);
    } catch (error) {
      console.error("Error hiding checklist:", error);
      // Handle error appropriately
    }
  };

  const handleEditChecklist = async (checklistId, currentChecklistName) => {
    const { value: newName } = await Swal.fire({
      title: "Edit Checklist Name",
      input: "text",
      inputValue: currentChecklistName,
      inputPlaceholder: "Enter new name",
      showCancelButton: true,
    });

    if (newName) {
      try {
        await axios.put(
          `${apiUrl}/maint/update_checklist_name/${checklistId}`,
          { checklist_name: newName }
        );
        // Optionally handle success
        fetchBlueprints(userID);
      } catch (error) {
        console.error("Error updating checklist name:", error);
        // Optionally handle error
      }
    }
  };

  const handleEditMaint = async (
    maintId,
    currentMaintName,
    maintScheduled,
    maintainer
  ) => {
    try {
      // Fetch the program id for the current maint
      const response = await axios.get(
        `${apiUrl}/maint/selected_program/${maintId}`
      );
      const currentProgramId = response.data;
      console.log("Current Program ID:", response.data);

      // Generate the HTML template with SweetAlert
      const { value: formValues } = await Swal.fire({
        title: "Edit Maint Details",
        html: `
      <input id="swal-input1" class="swal2-input" value="${currentMaintName}" placeholder="Maint Name" style="margin-bottom: 10px;">
      <input id="swal-input2" class="swal2-input" value="${maintScheduled}" placeholder="Scheduled Date" style="margin-bottom: 10px;">
      <input id="swal-input3" class="swal2-input" value="${maintainer}" placeholder="Maintainer" style="margin-bottom: 25px;">
      <select id="swal-input4" class="swal2-input" style="width: 57%; border: 1px solid #ccc;">
        ${maintPrograms.map(
          (program) =>
            `<option value="${program.id}" ${
              program.id === currentProgramId ? "selected" : ""
            }>${program.program_name}</option>`
        )}
      </select>`,
        focusConfirm: false,
        preConfirm: () => {
          return {
            maintName: document.getElementById("swal-input1").value,
            scheduledDate: document.getElementById("swal-input2").value,
            maintainer: document.getElementById("swal-input3").value,
            selectedProgramId: document.getElementById("swal-input4").value, // Get selected program ID
          };
        },
        didOpen: () => {
          // Initialize date picker for the scheduled date input
          const datePickerInput = document.getElementById("swal-input2");
          flatpickr(datePickerInput, {
            dateFormat: "Y-m-d", // Change format if necessary
            enableTime: false, // Disable time selection
          });
        },
      });

      if (formValues) {
        try {
          // Convert the scheduled date to the correct format
          const scheduledDate = new Date(
            formValues.scheduledDate
          ).toISOString();

          // Update maint details
          await axios.put(`${apiUrl}/maint/update_maint_details/${maintId}`, {
            maint_name: formValues.maintName,
            date_scheduled: scheduledDate,
            maintainer: formValues.maintainer,
          });

          // Update program ID
          console.log(
            "Selected Program ID:",
            formValues.selectedProgramId,
            "Maint ID:",
            maintId
          );
          await axios.post(`${apiUrl}/maint/update_program_detail`, {
            program_id: formValues.selectedProgramId,
            maint_id: maintId,
          });

          // Refresh maint list
          fetchMaintByOrgFromToken();
        } catch (error) {
          console.error("Error editing maint:", error);
          // Optionally handle error
        }
      }
    } catch (error) {
      console.error("Error fetching program ID:", error);
      // Optionally handle error
    }
  };

  const handleDeleteChecklist = async (checklistId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/maint/checklist_detail_exists/${checklistId}`
      );
      const hasDetails = response.data.hasDetails;

      let confirmationText = "";
      let confirmationButtonText = "";
      let confirmationAction = null;
      if (hasDetails) {
        confirmationText =
          "This checklist cannot be deleted because it is associated with maint details. We will hide it from view instead.";
        confirmationButtonText = "Hide";
        confirmationAction = hideChecklist;
      } else {
        confirmationText =
          "This checklist can be deleted. Are you sure you want to proceed?";
        confirmationButtonText = "Delete";
        confirmationAction = deleteChecklist;
      }

      Swal.fire({
        title: "Hold on a sec!",
        text: confirmationText,
        icon: "warning", // Use the warning icon
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: confirmationButtonText,
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        inputPlaceholder: `Type '${confirmationButtonText.toUpperCase()}' to confirm`,
        showLoaderOnConfirm: true,
        preConfirm: async (input) => {
          if (input !== confirmationButtonText.toUpperCase()) {
            Swal.showValidationMessage(
              `You need to type '${confirmationButtonText.toUpperCase()}' to confirm`
            );
          } else {
            await confirmationAction(checklistId);
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });
    } catch (error) {
      console.error("Error handling checklist deletion:", error);
      // Handle error appropriately
    }
  };

  const handleDeleteMaint = async (maintId) => {
    try {
      const response = await axios.get(`${apiUrl}/maint/progress/${maintId}`);
      const answeredCount = response.data.answered;

      if (answeredCount === 0) {
        Swal.fire({
          title: "Hold on a sec!",
          text: "Are you sure you want to delete this maint?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          confirmButtonText: "DELETE",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          inputPlaceholder: "Type 'DELETE' to confirm",
          showLoaderOnConfirm: true,
          preConfirm: async (input) => {
            if (input !== "DELETE") {
              Swal.showValidationMessage(
                "You need to type 'DELETE' to confirm"
              );
            } else {
              try {
                await deleteMaint(maintId);
                // If deletion is successful, you may want to perform any additional actions or update UI accordingly
              } catch (error) {
                console.error("Error deleting maint:", error);
                // Handle error appropriately
              }
            }
          },
          allowOutsideClick: () => !Swal.isLoading(),
        });
      } else {
        Swal.fire({
          title: `This maint cannot be deleted because it has been started.`,
          icon: "warning",
          showCancelButton: false,
          cancelButtonColor: "#3085d6",
          cancelButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error handling maint deletion:", error);
      // Handle error appropriately
    }
  };

  const deleteChecklist = async (checklistId) => {
    try {
      await axios.delete(`${apiUrl}/maint/delete_checklist/${checklistId}`);
      fetchBlueprints(userID);
    } catch (error) {
      console.error("Error deleting checklist:", error);
      // Handle error appropriately
    }
  };

  const deleteMaint = async (maintId) => {
    try {
      await axios.delete(`${apiUrl}/maint/delete_maint/${maintId}`);
      fetchMaintByOrgFromToken();
    } catch (error) {
      console.error("Error deleting checklist:", error);
      // Handle error appropriately
    }
  };

  const handleGenerateSmartMaint = async (checklistId) => {
    Swal.fire({
      title: "Get ready for some AI magic!",
      text: "Use past maint performance stats to generate a smarter checklist?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#4CAF50",
      confirmButtonText: "Yiha let's go!",
      cancelButtonColor: "#3085d6",
      cancelButtonText: "Nah",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "Generating Smart Maint Checklist!",
          "Sit back and relax while our AI does its thing....coming soon!",
          "success"
        );
        // Call function to generate the smart maint here
      }
    });
  };

  useEffect(() => {
    fetchUserID();
    fetchBlueprints(userID);
  }, [userID]);

  useEffect(() => {
    if (userID) {
      if (activeTab === "My Blueprints") {
        fetchBlueprints(userID);
      } else if (activeTab === "maintPrograms") {
        fetchMaintProgramsByOrg();
      } else if (activeTab === "maint") {
        fetchMaintByOrgFromToken();
      }
    }
  }, [userID, activeTab]);

  //Main render

  return (
    <ThemeProvider theme={theme}>
      <div className="container mx-auto px-6">
        <div className="flex items-center justify-between mt-5 mb-10">
          <div
            onClick={() => navigate("/admin")}
            className="text-primary cursor-pointer flex-shrink-0"
          >
            {theme.logoUrl ? (
              <img
                src={theme.logoUrl}
                alt="Membership Logo"
                className="h-20 w-auto"
              />
            ) : (
              <FontAwesomeIcon icon={faHomeLgAlt} size="2x" color="grey" />
            )}
          </div>
          <h1 className="text-4xl text-primary flex-grow text-center">
            Maintenance Management
          </h1>
          <div className="w-20"> {/* Placeholder to balance the header */}</div>
        </div>

        {/* Main content area */}
        <div className="flex">
          {/* Tab Navigation and Content */}
          <div className="w-4/5 pl-4">
            {" "}
            {/* Adjust width as needed */}
            <div className="flex mb-4">
              <STab
                className={activeTab === "maintPrograms" ? "active" : ""}
                onClick={() => setActiveTab("maintPrograms")}
              >
                Maintenance Schedule
              </STab>

              <STab
                className={activeTab === "maint" ? "active" : ""}
                onClick={() => setActiveTab("maint")}
              >
                Workpacks
              </STab>

              <STab
                className={activeTab === "My Blueprints" ? "active" : ""}
                onClick={() => setActiveTab("My Blueprints")}
              >
                Job Cards
              </STab>
            </div>
            {/* Tab Content */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {activeTab === "My Blueprints" && renderBlueprints()}
              {activeTab === "maintPrograms" && renderMaintPrograms()}
              {activeTab === "maint" && renderMaint()}
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default auditProtection(MaintHomePage);
