import React, { useContext } from "react";
import "../components/styles.css";
import { ThemeContext } from "../components/theme.js";

const FlightSelect = () => {
  const { theme } = useContext(ThemeContext);

  const handleButtonClick = (flightType) => {
    console.log(`${flightType} button clicked`);
    // Add your navigation or other logic here
  };

  return (
    <div className="flight-select-container">
      <div className="button-grid">
        <div
          className="bg-primary p-6 rounded-lg shadow-lg flex items-center justify-center button top-left"
          onClick={() => handleButtonClick("DFR Flight")}
          style={{ backgroundColor: theme.primary_colour }}
        >
          <h3 className="font-bold text-lg text-white">DFR Flight</h3>
        </div>
        <div
          className="bg-primary p-6 rounded-lg shadow-lg flex items-center justify-center button top-right"
          onClick={() => handleButtonClick("Tasking Flight")}
          style={{ backgroundColor: theme.primary_colour }}
        >
          <h3 className="font-bold text-lg text-white">Tasking Flight</h3>
        </div>
        <div
          className="bg-primary p-6 rounded-lg shadow-lg flex items-center justify-center button center"
          onClick={() => handleButtonClick("Live Flight")}
          style={{ backgroundColor: theme.primary_colour }}
        >
          <h3 className="font-bold text-lg text-white">Live Flight</h3>
        </div>
        <div
          className="bg-primary p-6 rounded-lg shadow-lg flex items-center justify-center button bottom-left"
          onClick={() => handleButtonClick("Training Flight")}
          style={{ backgroundColor: theme.primary_colour }}
        >
          <h3 className="font-bold text-lg text-white">Training Flight</h3>
        </div>
        <div
          className="bg-primary p-6 rounded-lg shadow-lg flex items-center justify-center button bottom-right"
          onClick={() => handleButtonClick("Maintenance Test Flight")}
          style={{ backgroundColor: theme.primary_colour }}
        >
          <h3 className="font-bold text-lg text-white">Maintenance Test Flight</h3>
        </div>
      </div>
    </div>
  );
};

export default FlightSelect;