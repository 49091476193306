import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

const AuditProgramForm = ({ onProgramAdded }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const formInitiatedFromHome = location.state?.fromHome || false;

  const [auditProgramHeader, setAuditProgramHeader] = useState({
    program_name: "",
    org_id: "",
    start_date: "",
    end_date: "",
    type_deployment: "",
    cat_deployment: "",
    subcat_deployment: "",
    subcat_result: "",
    function_id: "",
    district_id: "",
    storm_id: "",
    status: "",
    point_of_interest: "",
    details: "",
    is_active: false,
  });

  const [deploymentCategories, setDeploymentCategories] = useState([]);
  const [allSubcategories, setAllSubcategories] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const deploymentTypes = ["Live", "Tasking", "Training", "Maintenance Test Flight", "Testing DFR"];

  useEffect(() => {
    const membershipToken = localStorage.getItem("MToken");
    axios
      .post(`${apiUrl}/decode-membership-token/${membershipToken}`)
      .then((res) => {
        setAuditProgramHeader((prev) => ({
          ...prev,
          org_id: res.data.data.membership_data.org_id,
        }));
      })
      .catch((error) => {
        console.error("Error decoding membership token:", error);
      });

    // Fetch categories
    axios
      .get(`${apiUrl}/audit/program_categories`)
      .then((res) => {
        setDeploymentCategories(res.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });

    // Fetch subcategories
    axios
      .get(`${apiUrl}/audit/program_subcategories`)
      .then((res) => {
        setAllSubcategories(res.data); // Store all subcategories
      })
      .catch((error) => {
        console.error("Error fetching subcategories:", error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAuditProgramHeader((prev) => ({ ...prev, [name]: value }));

    // Update filtered subcategories when a category is selected
    if (name === "cat_deployment") {
      setFilteredSubcategories(allSubcategories.filter(subcat => subcat.category_id === value));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newAuditProgramHeader = {
        ...auditProgramHeader,
        is_active: true,
      };

      const response = await axios.post(
        `${apiUrl}/audit/generate_program_header`,
        newAuditProgramHeader
      );
      setAuditProgramHeader({}); // Reset form fields
      if (formInitiatedFromHome) navigate("/home");
      if (onProgramAdded) onProgramAdded();
    } catch (error) {
      console.error("Error creating audit program:", error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col w-full max-w-md p-8 space-y-8 bg-white shadow-xl rounded-lg"
      >
        <div>
          <label className="text-lg mb-2">Type of Deployment:</label>
          <select
            name="type_deployment"
            value={auditProgramHeader.type_deployment}
            onChange={handleChange}
            className="w-full border h-10 border-gray-300 px-3 rounded"
          >
            <option value="">Select Deployment Type</option>
            {deploymentTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>

        {["Live", "Tasking"].includes(auditProgramHeader.type_deployment) && (
          <>
            <div>
              <label className="text-lg mb-2">Deployment Category:</label>
              <select
                name="cat_deployment"
                value={auditProgramHeader.cat_deployment}
                onChange={handleChange}
                className="w-full border h-10 border-gray-300 px-3 rounded"
              >
                <option value="">Select Category</option>
                {deploymentCategories.map((cat) => (
                  <option key={cat.id} value={cat.id}>
                    {cat.category}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="text-lg mb-2">Subcategory:</label>
              <select
                name="subcat_deployment"
                value={auditProgramHeader.subcat_deployment}
                onChange={handleChange}
                className="w-full border h-10 border-gray-300 px-3 rounded"
              >
                <option value="">Select Subcategory</option>
                {filteredSubcategories.map((subcat) => (
                  <option key={subcat.id} value={subcat.id}>
                    {subcat.subcategory}
                  </option>
                ))}
              </select>
            </div>

            {auditProgramHeader.type_deployment === "Live" && (
              <div>
                <label className="text-lg mb-2">Storm Number:</label>
                <input
                  type="text"
                  name="storm_id"
                  value={auditProgramHeader.storm_id}
                  onChange={handleChange}
                  className="w-full border h-10 border-gray-300 px-3 rounded"
                />
              </div>
            )}
          </>
        )}

        {auditProgramHeader.type_deployment === "Tasking" && (
          <>
            <div>
              <label className="text-lg mb-2">Status:</label>
              <input
                type="text"
                name="status"
                value={auditProgramHeader.status}
                onChange={handleChange}
                className="w-full border h-10 border-gray-300 px-3 rounded"
              />
            </div>

            <div>
              <label className="text-lg mb-2">Point of Interest:</label>
              <input
                type="text"
                name="point_of_interest"
                value={auditProgramHeader.point_of_interest}
                onChange={handleChange}
                className="w-full border h-10 border-gray-300 px-3 rounded"
              />
            </div>

            <div>
              <label className="text-lg mb-2">Details:</label>
              <textarea
                name="details"
                value={auditProgramHeader.details}
                onChange={handleChange}
                className="w-full border h-20 border-gray-300 px-3 rounded"
              />
            </div>
          </>
        )}

        {["Training", "Maintenance Test Flight", "Testing DFR"].includes(auditProgramHeader.type_deployment) && (
          <div>
            <label className="text-lg mb-2">Program Name:</label>
            <input
              type="text"
              name="program_name"
              value={auditProgramHeader.program_name}
              onChange={handleChange}
              className="w-full border h-10 border-gray-300 px-3 rounded"
            />
          </div>
        )}

        <button
          type="submit"
          className="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded block mx-auto"
        >
          Create Job
        </button>
      </form>
    </div>
  );
};

export default AuditProgramForm;
